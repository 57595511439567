<template>
    <div class="page">
        <!-- <v-row v-if="!$platform.isMobile()" align="center" justify="center" style="margin: 0px">
            <v-col cols="12" sm="8" md="6" lg="3">
                <div class="div-router">
                    <router-view></router-view>
                </div>
            </v-col>
            <v-col cols="12" sm="8" md="6" lg="3">
                <div class="div-background"></div>
            </v-col>
        </v-row> -->
        <router-view></router-view>
    </div>
</template>

<script>
import { eventHub } from "@/main";

export default {
    name: "PublicLayout",

    computed: {
        isSmallDevice() {
            return ["xs", "sm"].indexOf(this.$vuetify.breakpoint.name) >= 0;
        },
    },

    data() {
        return {
            overlay: false,
            logoUrl: "/img/logo.png",
            menuItems: [
            ],
        };
    },

    created() {
        eventHub.$on("THEME_UPDATED", (config) => {
            this.logoUrl = config.logoUrl;
        });
    },

    methods: {},
};
</script>
<style lang="scss" scoped>
.page {
    height: 100%;
    //   background-color: rgba(0, 0, 0, 0.5);
}

// .v-app-bar {
// background: linear-gradient(
//   180deg,
//   var(--v-primary-base) 35.08%,
//   rgba(0, 0, 0, 0) 100%
// );
// background-blend-mode: multiply;
// }
.menu-link {
    font-weight: 700;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    margin-right: 30px;

    &:link {
        text-decoration: none;
    }

    &:hover {
        text-decoration: underline;
    }
}

.div-background {
    width: 60%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--v-primary-base);
    // background-color: #222D6C;
    transform: scale(1.1);
}

.div-router {
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
</style>